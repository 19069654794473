import React from 'react';
import Grid from '@mui/material/Grid';

import useViewport from '@Utils/UseViewport';
import pallette from '@Assets/Styles/pallette';
import image1 from '@Assets/Images2.0/client/Welcome.png';
import image2 from '@Assets/Images2.0/client/Booking.png';
import image3 from '@Assets/Images2.0/client/Parcel.png';


const avatar1 = require('@Assets/Images/avatar1.png');
const avatar2 = require('@Assets/Images/avatar2.png');
const avatar3 = require('@Assets/Images/avatar3.png');

const avatarStyle = {
  height: 300, width: 300, borderRadius: 0, marginBottom: 24,
  objectFit: 'contain',
  objectPosition: 'center', userSelect: 'none', cursor: 'pointer'
}

const h1Style = {
  display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center',
  fontFamily: 'SourceHanSans', fontSize: 40, fontWeight: 700,
  textShadow: `1px 1px 1px #303030A0`
}

const h2Style = {
  color: "#3C4858",
  fontSize: 24,
  textAlign: 'center',
  fontFamily: 'SourceHanSans', userSelect: 'none', cursor: 'pointer'
}

const pStyle = {
  display: "flex",
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  fontSize: 16,
  marginTop: 16,
  fontFamily: 'sans-serif',
  color: "#666666",
  lineHeight: 1.8,
  userSelect: 'none', cursor: 'pointer'
}

export default () => {
  const { width: windowWidth, height: windowHeight } = useViewport();

  return (
    <div
      style={{
        width: windowWidth > 1200 ? (960) : (480)
      }}>
      <div id={'section2'} style={{ position: 'relative', top: -128 }} />

      {/* <Grid container spacing={2}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <div style={{
            ...h1Style
          }}>
            项目案例
          </div>
        </Grid>
        <Grid item xs={2} />
      </Grid> */}
      {/* <div style={{ height: 48 }} /> */}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <img src={image1} style={{
              ...avatarStyle
            }} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <img src={image2} style={{
              ...avatarStyle
            }} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <img src={image3} style={{
              ...avatarStyle
            }} />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{
              ...h2Style
            }}>
              新闻、公告、推送消息
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{
              ...h2Style
            }}>
              设施预订
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{
              ...h2Style
            }}>
              快递收发
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{ ...pStyle }}>
              <>
                <>让您的业主第一时间获悉社区新闻或公告</><br />
                <>各类消息亦可分组群发，精准便捷</>
              </>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{ ...pStyle }}>
              <>
                <>自动处理预订提醒、收缴费等功能</><br />
                <>发挥小区内各类设施的全部价值</>
              </>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{ ...pStyle }}>
              <>
                <>为您的业主提供快递代收代发业务</><br />
                <>操作简单、功能强大</>
              </>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}