
import React from 'react';

import useViewport from '@Utils/UseViewport';
import MaskImageText from '@Components/MaskImageText';

import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.css";
import gradient from '@Assets/Images/gradient.jpg';
import carouselBG1 from '@Assets/Images2.0/background1.jpg';
import carouselBG2 from '@Assets/Images2.0/background2.jpg';
import carouselBG3 from '@Assets/Images2.0/background3.jpg';
import carouselBG4 from '@Assets/Images2.0/background4.jpg';
import image1 from '@Assets/Images2.0/client/Welcome.png';
import image2 from '@Assets/Images2.0/client/Booking.png';
import image3 from '@Assets/Images2.0/client/Parcel.png';

export default (props) => {
  const { width: windowWidth, height: windowHeight } = useViewport();
  const carouselItemStyle = {
    height: 0.6 * windowHeight,
    width: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }
  const carouselContainerStyle = {
    height: '100%',
    width: '100%',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  }
  const h1Style = {
    fontSize: 22,
    textShadow: `1px 1px 1px #303030A0`,
    textAlign: 'center',
    fontFamily: 'SourceHanSans', userSelect: 'none', cursor: 'pointer'
  }
  const h2Style = {
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 14,
    //marginTop: 16,
    marginBottom: 24,
    fontFamily: 'sans-serif',
    color: '#909090',
    lineHeight: 1.8,
    userSelect: 'none', cursor: 'pointer'
  }
  const gradientBlockStyle = {
    backgroundImage: `url(${gradient})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'center'
  }
  const gradientTextStyle = {
    ...gradientBlockStyle,
    WebkitBackgroundClip: 'text',
    color: "transparent",
    textFillColor: 'transparent',
    //textShadow: 'none'
  }
  return (
    <Carousel
      showArrows={true}
      autoPlay={true}
      infiniteLoop={true}
      interval={5000}
      showStatus={false}
    >
      <div style={{ width: '100%' }}>
        <div style={{
          ...carouselContainerStyle,
        }}>
          <div style={{ height: 60 }}></div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: "center" }}>
            <div style={{ ...h1Style, marginBottom: 12 }}>
              新闻、公告、推送消息
            </div>
            <div style={{ ...h2Style, textAlign: 'center' }}>
              <>
                <>让您的业主第一时间获悉社区新闻或公告</><br />
                <>各类消息亦可分组群发，精准便捷</>
              </>
            </div>
          </div>
          <div style={{ width: 150, height: 150, display: 'flex', justifyContent: 'center' }}>
            <img
              alt="..."
              src={image1}
              style={{
                width: "100%",
                objectFit: "contain"
              }}
            />
          </div>
          <div style={{ height: 40 }}></div>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <div style={{
          ...carouselContainerStyle,
        }}>
          <div style={{ height: 60 }}></div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: "center" }}>
            <div style={{ ...h1Style, marginBottom: 12 }}>
              设施预订
            </div>
            <div style={{ ...h2Style, textAlign: 'center', marginBottom: 8 }}>
              <>
                <>自动处理预订提醒、收缴费等功能</><br />
                <>发挥小区内各类设施的全部价值</>
              </>
            </div>

          </div>
          <div style={{ width: 150, height: 150, display: 'flex', justifyContent: 'center' }}>
            <img
              alt="..."
              src={image2}
              style={{
                width: "100%",
                objectFit: "contain"
              }}
            />
            {/* <div style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => { props.link1Action() }}>
                <MaskImageText
                  text={'更多软件高端定制'}
                  textStyle={h2Style}
                  maskImageUrl={gradient}
                />
              </div> */}
          </div>
          <div style={{ height: 40 }}></div>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <div style={{
          ...carouselContainerStyle,
        }}>
          <div style={{ height: 60 }}></div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: "center" }}>
            <div style={{ ...h1Style, marginBottom: 12 }}>
              快递收发
            </div>
            <div style={{ ...h2Style, textAlign: 'center', marginBottom: 8 }}>
              <>
                <>为您的业主提供快递代收代发业务</><br />
                <>操作简单、功能强大</>
              </>
            </div>
            {/* <div style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => { props.link2Action() }}>
                <MaskImageText
                  text={'——联系我们'}
                  textStyle={h2Style}
                  maskImageUrl={gradient}
                />
              </div> */}
          </div>
          <div style={{ width: 150, height: 150, display: 'flex', justifyContent: 'center' }}>
            <img
              alt="..."
              src={image3}
              style={{
                width: "100%",
                objectFit: "contain"
              }}
            />
          </div>
          <div style={{ height: 40 }}></div>
        </div>
      </div>
    </Carousel >
  )
}



// import React from 'react';
// import Grid from '@mui/material/Grid';

// import useViewport from '@Utils/UseViewport';
// import pallette from '@Assets/Styles/pallette';


// const avatar1 = require('@Assets/Images/avatar1.png');
// const avatar2 = require('@Assets/Images/avatar2.png');
// const avatar3 = require('@Assets/Images/avatar3.png');

// const avatarStyle = {
//   height: 144, width: 144, borderRadius: 72, marginBottom: 12,
//   objectFit: 'cover',
//   objectPosition: 'center', userSelect: 'none', cursor: 'pointer'
// }

// const h1Style = {
//   display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center',
//   fontFamily: 'SourceHanSans', fontSize: 32, fontWeight: 700,
//   textShadow: `1px 1px 1px #303030A0`
// }

// const h2Style = {

//   fontSize: 22,
//   textShadow: `1px 1px 1px #303030A0`,
//   textAlign: 'center',
//   fontFamily: 'SourceHanSans', userSelect: 'none', cursor: 'pointer'
// }

// const pStyle = {
//   display: "flex",
//   flexDirection: 'column',
//   alignItems: 'center',
//   textAlign: 'center',
//   fontSize: 14,
//   //marginTop: 16,
//   marginBottom: 24,
//   fontFamily: 'sans-serif',
//   color: '#909090',
//   lineHeight: 1.8,
//   userSelect: 'none', cursor: 'pointer'
// }

// export default () => {
//   const { width: windowWidth, height: windowHeight } = useViewport();

//   return (
//     <div
//       style={{
//         width: "100%"
//       }}>
//       <div style={{ height: 48 }} />
//       <Grid container spacing={2}>
//         <Grid item xs={2} />
//         <Grid item xs={8}>
//           <div style={{
//             ...h1Style
//           }}>
//             项目案例
//           </div>
//         </Grid>
//         <Grid item xs={2} />
//       </Grid>
//       <div style={{ height: 48 }} />
//       <Grid container spacing={1}>
//         <Grid item xs={12}>
//           <div onClick={() => { window.open('https://prop.shengjingxing.com', '_blank') }} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
//             <img src={avatar1} style={{
//               ...avatarStyle
//             }} />
//           </div>
//         </Grid>
//         <Grid item xs={12}>
//           <div onClick={() => { window.open('https://prop.shengjingxing.com', '_blank') }} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
//             <div style={{
//               ...h2Style
//             }}>
//               物业服务SaaS管理平台
//             </div>
//           </div>
//         </Grid>
//         <Grid item xs={12}>
//           <div onClick={() => { window.open('https://prop.shengjingxing.com', '_blank') }} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
//             <div style={{ ...pStyle }}>
//               <>
//                 <>“互联网+社区”理念的应用</><br />
//                 <>让企业业务更高效，让住户生活更便捷</><br />
//                 <>放大资源整合的价值，探索全新业态</>
//               </>
//             </div>
//           </div>
//         </Grid>


//       </Grid>

//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <div onClick={() => { window.open('https://taxi.shengjingxing.com', '_blank') }} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
//             <img src={avatar3} style={{
//               ...avatarStyle
//             }} />
//           </div>
//         </Grid>
//         <Grid item xs={12}>
//           <div onClick={() => { window.open('https://taxi.shengjingxing.com', '_blank') }} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
//             <div style={{
//               ...h2Style
//             }}>
//               出租车管理与调度系统
//             </div>
//           </div>
//         </Grid>
//         <Grid item xs={12}>
//           <div onClick={() => { window.open('https://taxi.shengjingxing.com', '_blank') }} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
//             <div style={{ ...pStyle }}>
//               <>
//                 <>出租车管理智慧解决方案</><br />
//                 <>AI实时分析路况与用车需求</><br />
//                 <>应用大数据的力量为出租车行业赋能</>
//               </>
//             </div>
//           </div>
//         </Grid>

//       </Grid>
//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <div onClick={() => { window.open('https://tank.shengjingxing.com', '_blank') }} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
//             <img src={avatar2} style={{
//               ...avatarStyle
//             }} />
//           </div>
//         </Grid>
//         <Grid item xs={12}>
//           <div onClick={() => { window.open('https://tank.shengjingxing.com', '_blank') }} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
//             <div style={{
//               ...h2Style
//             }}>
//               特种车辆物理仿真模拟训练系统
//             </div>
//           </div>
//         </Grid>
//         <Grid item xs={12}>
//           <div onClick={() => { window.open('https://tank.shengjingxing.com', '_blank') }} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
//             <div style={{ ...pStyle }}>
//               <>
//                 <>为独特业务全面定制解决方案</><br />
//                 <>设施、环境、物理性质1:1仿真模拟</><br />
//                 <>精准完成项目方业务需求</>
//               </>
//             </div>
//           </div>
//         </Grid>
//       </Grid>
//       <div style={{ height: 48 }} />
//     </div>
//   )
// }