
import React from 'react';

import useViewport from '@Utils/UseViewport';
import MaskImageText from '@Components/MaskImageText';

import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.css";
import gradient from '@Assets/Images/gradient.jpg';
import carouselBG1 from '@Assets/Images2.0/background1.jpg';
import carouselBG2 from '@Assets/Images2.0/background2.jpg';
import carouselBG3 from '@Assets/Images2.0/background3.jpg';
import carouselBG4 from '@Assets/Images2.0/background4.jpg';
import carouseImg1 from '@Assets/Images2.0/home/Main.png';
import carouseImg2 from '@Assets/Images2.0/home/Community.png';
import carouseImg4 from '@Assets/Images2.0/home/MuiltiPlatform.png';

export default (props) => {
  const { width: windowWidth, height: windowHeight } = useViewport();
  const carouselItemStyle = {
    height: 810,
    width: windowWidth,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }
  const carouselContainerStyle = {
    height: '100%',
    width: '100%',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  }
  const h1Style = {
    color: 'white',
    textShadow: "4px 4px 4px #30303090",
    fontFamily: 'SansSourceHans',
    fontWeight: 700,
    fontSize: 48,
    marginBottom: 16,
    lineHeight: 1.4
  }
  const h2Style = {
    color: 'white',
    textShadow: "4px 4px 4px #30303090",
    fontFamily: 'SansSourceHans',
    fontWeight: 700,
    fontSize: 28,
    marginBottom: 16,
    lineHeight: 1.6
  }
  const gradientBlockStyle = {
    backgroundImage: `url(${gradient})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'center'
  }
  const gradientTextStyle = {
    ...gradientBlockStyle,
    WebkitBackgroundClip: 'text',
    color: "transparent",
    textFillColor: 'transparent',
    //textShadow: 'none'
  }
  return (
    <Carousel
      showArrows={true}
      autoPlay={true}
      infiniteLoop={true}
      interval={3000}
      showStatus={false}
      showIndicators={false}

    >
      <div style={{ ...carouselItemStyle, backgroundImage: `url(${carouselBG1})` }}>
        <div style={{
          ...carouselContainerStyle,
          backgroundColor: 'rgba(0,0,0,0.3)',
          display: 'flex',
          flexDirection: 'row'
        }}>
          <div style={{ width: 480, display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
            <div style={{ ...h1Style }}>
              物业管家
            </div>
            <div style={{ ...gradientBlockStyle, height: 8, width: 240, marginBottom: 12 }} />
            <div style={{ ...h2Style, textAlign: 'left' }}>
              高效、智能、强大
              <br />
              物业管理智慧解决方案
              <br />
              让企业业务更高效，让业主生活更美好
            </div>
            <div
              style={{ cursor: 'pointer', userSelect: 'none', flexDirection: 'row-reverse' }}
              onClick={() => { props.link2Action() }}>
              <MaskImageText
                text={'联系我们'}
                textStyle={h2Style}
                maskImageUrl={gradient}
              />
            </div>
          </div>
          <div style={{ width: 480, display: 'flex', justifyContent: 'center' }}>
            <img
              alt="..."
              src={carouseImg1}
              style={{
                width: "100%",
                height: "61.8vh",
                maxHeight: "600px",
                objectFit: "contain"
              }}
            />
          </div>

        </div>
      </div>
      <div style={{ ...carouselItemStyle, backgroundImage: `url(${carouselBG2})` }}>
        <div style={{
          ...carouselContainerStyle,
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: 'rgba(0,0,0,0.3)',
        }}>
          <div style={{ width: 480, display: 'flex', justifyContent: 'center' }}>
            <img
              alt="..."
              src={carouseImg2}
              style={{
                width: "100%",
                height: "61.8vh",
                maxHeight: "600px",
                objectFit: "contain"
              }}
            />
          </div>
          <div style={{ width: 480 }}>
            <div style={{
              display: "flex",
              flexDirection: 'column',
              alignItems: "flex-end"
            }}>
              <div style={{ ...h1Style, marginBottom: 12 }}>
                社区+社交
              </div>
              <div style={{ ...gradientBlockStyle, height: 8, width: 240, marginBottom: 12 }} />
              <div style={{ ...h2Style, textAlign: 'right', marginBottom: 8 }}>
                当物业聚焦用户<br />
                将资源整合与用户聚集的价值再次放大<br />
                赋能物业管理，探索全新业态
              </div>
              {/* <div style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => { props.link1Action() }}>
                <MaskImageText
                  text={'更多软件高端定制'}
                  textStyle={h2Style}
                  maskImageUrl={gradient}
                />
              </div> */}
            </div>
          </div>

        </div>
      </div>
      <div style={{ ...carouselItemStyle, backgroundImage: `url(${carouselBG3})` }}>
        <div style={{
          ...carouselContainerStyle,
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: 'rgba(0,0,0,0.3)',
        }}>
          <div style={{ width: 480 }}>
            <div style={{
              display: "flex",
              flexDirection: 'column',
              alignItems: "flex-start"
            }}>
              <div style={{ ...h1Style, marginBottom: 12 }}>
                SaaS云服务
              </div>
              <div style={{ ...gradientBlockStyle, height: 8, width: 240, marginBottom: 12 }} />
              <div style={{ ...h2Style, textAlign: 'left', marginBottom: 8 }}>
                软件即服务<br />
                一站式满足企业与住户各方需求<br />
                降低业务成本、提升工作效率<br />
                保护数据安全
              </div>
              {/* <div style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => { props.link2Action() }}>
                <MaskImageText
                  text={'——联系我们'}
                  textStyle={h2Style}
                  maskImageUrl={gradient}
                />
              </div> */}
            </div>
          </div>
          <div style={{ width: 480 }}>
            <img
              alt="..."
              src={carouseImg1}
              style={{
                width: "100%",
                height: "61.8vh",
                maxHeight: "600px",
                objectFit: "contain"
              }}
            />
          </div>

        </div>
      </div>
      <div style={{ ...carouselItemStyle, backgroundImage: `url(${carouselBG4})` }}>
        <div style={{
          ...carouselContainerStyle,
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: 'rgba(0,0,0,0.3)',
        }}>
          <div style={{ width: 480, display: 'flex', justifyContent: 'center' }}>
            <img
              alt="..."
              src={carouseImg4}
              style={{
                width: "100%",
                height: "61.8vh",
                maxHeight: "600px",
                objectFit: "contain"
              }}
            />
          </div>
          <div style={{ width: 480 }}>
            <div style={{
              display: "flex",
              flexDirection: 'column',
              alignItems: "flex-end"
            }}>
              <div style={{ ...h1Style, marginBottom: 12 }}>
                应用跨平台
              </div>
              <div style={{ ...gradientBlockStyle, height: 8, width: 240, marginBottom: 12 }} />
              <div style={{ ...h2Style, textAlign: 'right', marginBottom: 8 }}>
                响应式设计<br />
                跨平台客户端<br />
                各类设备全通用
              </div>
              {/* <div style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => { props.link1Action() }}>
                <MaskImageText
                  text={'更多软件高端定制'}
                  textStyle={h2Style}
                  maskImageUrl={gradient}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Carousel >
  )
}