import React from 'react';
import Grid from '@mui/material/Grid';

import useViewport from '@Utils/UseViewport';
import pallette from '@Assets/Styles/pallette';
import image1 from '@Assets/Images2.0/client/Market.png';
import image2 from '@Assets/Images2.0/client/Neighblog.png';
import image3 from '@Assets/Images2.0/client/Store.png';


const avatar1 = require('@Assets/Images/avatar1.png');
const avatar2 = require('@Assets/Images/avatar2.png');
const avatar3 = require('@Assets/Images/avatar3.png');

const avatarStyle = {
  height: 300, width: 300, borderRadius: 0, marginBottom: 24,
  objectFit: 'contain',
  objectPosition: 'center', userSelect: 'none', cursor: 'pointer'
}

const h1Style = {
  display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center',
  fontFamily: 'SourceHanSans', fontSize: 40, fontWeight: 700,
  textShadow: `1px 1px 1px #303030A0`
}

const h2Style = {
  color: "#3C4858",
  fontSize: 24,
  textAlign: 'center',
  fontFamily: 'SourceHanSans', userSelect: 'none', cursor: 'pointer'
}

const pStyle = {
  display: "flex",
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  fontSize: 16,
  marginTop: 16,
  fontFamily: 'sans-serif',
  color: "#666666",
  lineHeight: 1.8,
  userSelect: 'none', cursor: 'pointer'
}

export default () => {
  const { width: windowWidth, height: windowHeight } = useViewport();

  return (
    <div
      style={{
        width: windowWidth > 1200 ? (960) : (480)
      }}>
      <div id={'section2'} style={{ position: 'relative', top: -128 }} />

      {/* <Grid container spacing={2}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <div style={{
            ...h1Style
          }}>
            项目案例
          </div>
        </Grid>
        <Grid item xs={2} />
      </Grid> */}
      {/* <div style={{ height: 48 }} /> */}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <img src={image1} style={{
              ...avatarStyle
            }} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <img src={image2} style={{
              ...avatarStyle
            }} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <img src={image3} style={{
              ...avatarStyle
            }} />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{
              ...h2Style
            }}>
              市场
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{
              ...h2Style
            }}>
              圈子
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{
              ...h2Style
            }}>
              社区团购
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{ ...pStyle }}>
              <>
                <>邻里之间的二手交易平台</><br />
                <>真正发挥用户聚集的优势</><br />
                <>活跃、高效、近距离</>
              </>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{ ...pStyle }}>
              <>
                <>只属于您社区的独特社交平台</><br />
                <>真正自媒体，社区+社交</><br />
                <>探索互联网+物业新业态</>
              </>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{ ...pStyle }}>
              <>
                <>小区自己的社区团购平台</><br />
                <>提升消费体验，降低物流成本</><br />
                <>赋能物业增值创收</>
              </>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}