import React from 'react';
import Grid from '@mui/material/Grid';

import useViewport from '@Utils/UseViewport';
import pallette from '@Assets/Styles/pallette';



import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import SettingsIcon from '@mui/icons-material/Settings';
import GradingIcon from '@mui/icons-material/Grading';
import BiotechIcon from '@mui/icons-material/Biotech';

import carouseImg1 from '@Assets/Images2.0/home/Main.png';

const avatar1 = require('@Assets/Images/avatar1.png');
const avatar2 = require('@Assets/Images/avatar2.png');
const avatar3 = require('@Assets/Images/avatar3.png');



const carouselContainerStyle = {
  height: '100%',
  width: '100%',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column"
}
const h1Style = {
  color: "#3C4858",
  fontFamily: 'SansSourceHans',
  fontWeight: 700,
  fontSize: 40,
  marginBottom: 16,
  lineHeight: 1.4
}
const h2Style = {
  color: "#666666",
  fontFamily: 'SansSourceHans',
  fontWeight: 400,
  fontSize: 20,
  marginBottom: 16,
  lineHeight: 1.6,
  textAlign: 'left'
}
const ContentBlock = (props) => {
  return (
    <div style={{
      //display: "flex",
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 420,
      textAlign: 'center',
      fontFamily: 'SourceHanSans',
      userSelect: 'none'
    }} >

      <div style={{
        flexGrow: 1
      }}>
        <div style={{ userSelect: 'none', cursor: 'pointer' }}
        //onClick={() => { window.location.href = props.href }}
        >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transform: "translate(36px,0px)",
            height: 144, width: 144, borderRadius: 72, marginBottom: 24,
            fontSize: 48,
            backgroundColor: pallette.smokeBlue,
          }}>
            {props.icon}
          </div>
        </div>

        <div style={{
          fontSize: 28,
          textShadow: `1px 1px 1px #303030A0`,
          marginBottom: 18,
          textAlign: 'center'
        }}>
          {props.title}
        </div>
        <div style={{
          marginLeft: 24,
          fontSize: 16,
          fontFamily: 'sans-serif',
          color: '#909090',
          lineHeight: 1.8,
          textAlign: 'left',
        }}>
          {props.text}
        </div>
      </div>
    </div>
  )
}

export default () => {
  const { width: windowWidth, height: windowHeight } = useViewport();

  return (
    <div
      style={{
        width: windowWidth > 1200 ? (960) : (480)
      }}>
      <div id={'section1'} style={{ position: 'relative', top: -64 }} />
      <div style={{
        ...carouselContainerStyle,
        display: 'flex',
        flexDirection: 'row'
      }}>
        <div style={{ width: 480, display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
          <div style={{ ...h1Style }}>
            高效运营
          </div>
          <div style={{ ...h2Style, textAlign: 'left' }}>
            软件即服务<br />
            为物业管理提供一站式的服务平台
            <br />
            界面简单人性化，简单易用
            <br />
            无纸化办公，减少繁重陈旧工作方式
            <br />
            降低业务成本、提升工作效率、保护数据安全
          </div>
        </div>
        <div style={{ width: 480, display: 'flex', justifyContent: 'center' }}>
          <img
            alt="..."
            src={carouseImg1}
            style={{
              width: "100%",
              height: "61.8vh",
              maxHeight: "600px",
              objectFit: "contain"
            }}
          />
        </div>

      </div>
      {/* <Grid container spacing={2}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <div style={{
            display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center',
            fontFamily: 'SourceHanSans', fontSize: 40, fontWeight: 700,
            textShadow: `1px 1px 1px #303030A0`
          }}>
            业务范围
          </div>
        </Grid>
        <Grid item xs={2} />
      </Grid> */}
      {/* <div style={{ height: 48 }} /> */}
      {/* <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <ContentBlock icon={<TravelExploreIcon style={{ fontSize: 72, color: pallette.darkBlue }} />} title={'网站定制开发'} href={'https://taxi.shengjingxing.com'} text={<>
              · 商业洞察 · 文案策划<br />
              · 概念设计 · 视觉设计<br />
              · 1对1定制，唯一、独占<br />
              · 强大的SEO搜索引擎优化<br />
              · 响应式布局，提升用户体验<br />
            </>} />
          </div>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <ContentBlock icon={<SettingsIcon style={{ fontSize: 72, color: pallette.darkBlue }} />} title={'行业软件开发'} href={'https://taxi.shengjingxing.com'} text={<>
              · 社交 · 电商 · 工业<br />
              · 共享经济 · 物联网<br />
              · PC、APP、小程序<br />
              · 深耕各行业用户需求<br />
              · 全面定制行业解决方案<br />

            </>} />
          </div>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <ContentBlock icon={<GradingIcon style={{ fontSize: 72, color: pallette.darkBlue }} />} title={'管理软件定制'} href={'https://taxi.shengjingxing.com'} text={<>
              · ERP · 进销存 · CRM<br />
              · 项目管理 · 数据可视化<br />
              · 提升业务效率、数据安全<br />
              · 可选本地部署/SaaS云服务<br />
              · 助力企业实现数字化转型<br />

            </>} />
          </div>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <ContentBlock icon={<BiotechIcon style={{ fontSize: 72, color: pallette.darkBlue }} />} title={'前沿技术开发'} href={'https://taxi.shengjingxing.com'} text={<>
              · 智慧城市 · 大数据<br />
              · 虚拟仿真 · 数字孪生<br />
              · 人工智能开发与应用<br />
              · 重点难点项目攻克<br />
              · 技术优势业内领先<br />
            </>} />
          </div>
        </Grid>
      </Grid> */}
    </div>
  )
}