//import logo from '@Assets/Images/logo.png';
import './App.css';

import React from 'react';

import { ParallaxProvider, useParallax } from 'react-scroll-parallax';




import useViewport from '@Utils/UseViewport';


import mainBG from "@Assets/Images/BGS_01.png";


import Header from '@Components/Header';
import HeadCarousel from '@Pages/PC/HeadCarousel';
import Section1 from '@Pages/PC/Section1';
import Section2 from '@Pages/PC/Section2';
import Section3 from '@Pages/PC/Section3';
import Section4 from '@Pages/PC/Section4';
import Section5 from '@Pages/PC/Section5';
import Section6 from '@Pages/PC/Section6';
import Section7 from '@Pages/PC/Section7';
import ContactUsSection from '@Pages/PC/ContactUs';


function App() {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const { width: windowWidth, height: windowHeight } = useViewport();
  const { ref: cardRef } = useParallax({ speed: 44 });
  const { ref: mainRef } = useParallax({ speed: 0 });

  const sec1Ref = React.useRef(null);
  const sec2Ref = React.useRef(null);
  const sec3Ref = React.useRef(null);

  const scrollToSec = (secId) => {
    switch (secId) {
      case 'top':
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        break;
      case 1:
        window.scrollTo({
          top: sec1Ref.current.offsetTop,
          left: 0,
          behavior: 'smooth'
        });
        break;

      case 2:
        window.scrollTo({
          top: sec2Ref.current.offsetTop,
          left: 0,
          behavior: 'smooth'
        });
        break;

      case 'bottom':
        console.log(window.innerHeight);
        window.scrollTo({
          top: document.body.scrollHeight,
          left: 0,
          behavior: 'smooth'
        });
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Header pageIndex={currentIndex} setPageIndex={setCurrentIndex} scrollToSec={scrollToSec} />
      <div style={{ width: '100%', zIndex: 18 }}>
        <HeadCarousel link1Action={() => { scrollToSec(2) }} link2Action={() => { scrollToSec('bottom') }} />
      </div>

      <div ref={mainRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', backgroundColor: '#FAFAFA', backgroundSize: "100% 100%", marginTop: -64, paddingTop: 96 }}>
        <div ref={cardRef} style={{
          marginTop: -518, zIndex: 20,
          width: '95%', borderRadius: 16, boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
          display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white'
        }}>
          <div style={{ height: 80 }} />
          <div style={{
            width: '100%',
            height: 760,
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <div style={{ position: 'relative', top: 400 }} ref={sec1Ref} />
            <Section1 />

          </div>

          <div style={{
            width: '100%',
            height: 760,
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: "rgb(250,250,250)"
          }}>
            <div style={{ position: 'relative', top: 80 }} ref={sec2Ref} />
            <Section2 />

          </div>
          <div style={{
            width: '100%',
            height: 760,
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <Section3 />
          </div>

          <div style={{
            width: '100%',
            height: 760,
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: "rgb(250,250,250)"
          }}>
            <Section4 />

          </div>
          <div style={{
            width: '100%',
            height: 760,
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <Section5 />
          </div>

          <div style={{
            width: '100%',
            height: 760,
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: "rgb(250,250,250)"
          }}>
            <Section6 />

          </div>
          <div style={{
            width: '100%',
            height: 760,
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <Section7 />

          </div>
          <div style={{ height: 80, width: '100%', borderRadius: '0px 0px 16px 16px' }} />
        </div>
        <div style={{ height: 48 }} />
        <div style={{ flexDirection: 'column', width: "72%", display: 'flex', alignItems: 'center', justifyContent: 'center', transform: "translate(0px,-120px)", zIndex: 19 }}>
          <ContactUsSection />
        </div>
        <div  >
          <a href="https://beian.miit.gov.cn"
            target="_blank"
            style={{
              height: 64,
              display: 'flex',
              flexDirection: 'column-reverse',
              fontFamily: 'sans-serif',
              color: '#909090',
              marginBottom: 12
            }}>辽ICP备2021003615号-1</a>
        </div>
      </div>
    </>
  );
}

export default App;
